.container {
    padding: 2rem;
    /* height: 100vh; */
    /* width: 100%; */
}

@media screen and (max-width: 880px) {
    .container {
        /* width: 100%; */
    }
  }