.privacyTitle {
    text-align: center;
    font-size: 45px;
    font-weight: bolder;
    margin-top: 3rem;
}

.privacyBody {
    padding: 7rem;
    line-height: 30px;
}

.privacyBold {
    font-weight: bolder;
}

.privacyLogo {
    padding-left: 7rem;
    margin-top: 3rem;
}

.privacyImage {
    width: 200px;
}

@media screen and (max-width: 880px) {
    .privacyLogo {
        padding-left: 5rem;
    }

    .privacyBody {
        padding: 2rem;
    }
  }