.landingContainer {
    margin-top: 5rem;
    width: 100%;
    position: relative;
    height: 600px;
    padding-left:2rem;
}

@media screen and (max-width: 880px) {
    .landingContainer {
        padding-left: 0;
    }
  }

.logoContainer {
    width: 100%;
}

.logo {
    width: 200px;
}

.slogan {
    font-size: 55px;
    font-weight: 900;
    margin-top: 1.5rem;
}

.blue {
    color: #2059EA;
}

.black {
    color: #3B404B;
}

.caption {
    margin-top: 2rem;
    font-size: 25px;
    color:rgb(30, 29, 29)
}

.buy {
    margin-top: 2rem;
    background-color: #2059EA;
    width: 130px;
    border-radius: 20px;
    color: white;
    border: none;
    height: 40px;
    font-weight: bolder;
    cursor: pointer;
}


.globe {
    z-index: -1;
    width: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
}

span {
    color: rgb(55, 54, 54);
    font-weight: bold;
}

@media screen and (max-width: 880px) {
    .slogan {
        font-size: 45px;
    }
  }