.infoContainer {
    width: 100%;
    position: relative;
    background-color: #2059EA;
    color: white;
    border-radius: 15px;
    padding-top: 3rem;
}

.infoTitle {
    text-align: center;
    font-size: 45px;
    font-weight: bolder;
    margin-top: 3rem;
}

.infoBody {
    padding: 3rem;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 880px) {
    .infoBody {
        display: block;
    }
  }

.infoSection {
    padding: 2rem;
    text-align: center;
}

.sectionImageContainer {
    width: 80px;
    height: 80px;
    background-color: white;
    border-radius: 50%;
    margin: auto;
}

.sectionIcon {
    width: 40px;
    text-align: center;
    padding: 1rem;
    margin-top: 6px;
}

.sectionTitle {
    margin-top: 1rem;
    font-weight: bolder;
    font-size: 20px;
}