.telegramContainer {
    text-align: center;
    background-color: #2059EA;
    color: white;
    padding: 2rem;
}

.telegramTitle {
    text-align: center;
    font-size: 45px;
    font-weight: bolder;
    margin-top: 3rem;
}

.telegramCaption {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.telegramButton {
    background-color: white;
    border: none;
    padding: 1rem;
    border-radius: 5px;
    color: #2059EA;
    cursor: pointer;
    font-size: 15px;
    width: 200px;
    margin: auto;
    font-weight: bolder;
    margin-bottom: 3rem;
}