.footerContainer {
    background-color: #2C2E30;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
}

.footerLeft {
    display: flex;
    color: rgb(100, 98, 98);
    margin-top: 1rem;
}

.footerRight {
    display: flex;
}

.twitter, .telegram {
    width: 35px;
    margin: 10px;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}


a {
    text-decoration: none;
    color: rgb(100, 98, 98);
}