.faqContainer {
   margin-top: 5rem;
}

.faqTitle {
    text-align: center;
    font-size: 45px;
    font-weight: bolder;
    margin-top: 3rem;
}

.faqs {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    padding: 15px;
  }
  
  .faqs .faq {
    padding: 15px;
    background: #fff;
    border-radius: 10px;
    width: 70%;
    margin: auto;
  }
  
  .faqs .faq .faq-question {
    position: relative;
    font-size: 20px;
    padding-right: 80px;
    transition: all 0.4s ease;
  }
  
  .faqs .faq .faq-question::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.3s ease;
  }
  
  .faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
    color: #2B5DE2;
  }
  
  .faqs .faq.open .faq-question {
    margin-bottom: 15px;
  }
  
  .faqs .faq.open .faq-question::after {
    content: "-";
    color: #2B5DE2;
  }
  
  .faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
  }
  