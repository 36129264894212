.linkContainer {
    width: 100%;
}

.linkTitle {
    text-align: center;
    margin-top: 3rem;
    font-size: 25px;
    font-weight: bolder;
    color: #54595F;
}

/* .linkBody {
    width: 100%;
} */

.linkForm {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.linkType {
    max-width: 80%;
    border-radius: 26px;
    border-color: #e5e5e5;
    border: 1px solid lightgray;
    width: 100%;
    padding: .75rem;
}

.linkSubmit {
    border-radius: 26px;
    background-color: #2B5DE2;
    border: none;
    color: #fff;
    padding: .75rem;
    width: 10%;
    margin-left: 10px;
}

@media screen and (max-width: 880px) {
    .linkType {
        max-width: 70%;
    }

    .linkSubmit {
        width: 20%;
    }
  }