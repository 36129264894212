.registrarContainer {
    width: 100%;
    position: relative;
    background-color: #EFF2F6;
    border-radius: 15px;
    padding-top: 3rem;
}

.registrarTitle {
    text-align: center;
    font-size: 45px;
    font-weight: bolder;
    margin-top: 3rem;
}

.registrarBody {
    padding: 3rem;
    display: flex;
    justify-content: center;
}



.registrarSection {
    padding: 2rem;
    text-align: center;
}

.registrarSctionImageContainer {
    width: 80px;
    height: 80px;
    background-color: white;
    border-radius: 50%;
    margin: auto;
}

.registrarSectionTitle {
    margin-top: 1rem;
    font-weight: bolder;
    font-size: 20px;
}

.registrarImage {
    width: 200px;
    cursor: pointer;
}

@media screen and (max-width: 880px) {
    .registrarSection  {
        padding: 0;
    }

    .registrarSctionImageContainer {
        width: 50px;
        height: 50px;
    }

    .registrarImage {
        width: 100px;
        cursor: pointer;
        margin: 10px;
    }
  }